/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EnergyStandardsResponseGetRecordsList } from '../../models/energy-standards-response-get-records-list';

export interface BuildingsControllerEnergyStandards$Params {

/**
 * ISO standard Alpha-2 country code.
 */
  countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';
}

export function buildingsControllerEnergyStandards(http: HttpClient, rootUrl: string, params: BuildingsControllerEnergyStandards$Params, context?: HttpContext): Observable<StrictHttpResponse<EnergyStandardsResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, buildingsControllerEnergyStandards.PATH, 'get');
  if (params) {
    rb.query('countryCode', params.countryCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EnergyStandardsResponseGetRecordsList>;
    })
  );
}

buildingsControllerEnergyStandards.PATH = '/planning-projects/v1-preview/buildings/energy-standards';
