/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { economicsControllerGetEnergyPrices } from '../fn/economics-controller/economics-controller-get-energy-prices';
import { EconomicsControllerGetEnergyPrices$Params } from '../fn/economics-controller/economics-controller-get-energy-prices';
import { EnergyPricesResponseGetRecordsList } from '../models/energy-prices-response-get-records-list';


/**
 * Represents all API calls related to economics and prices topics.
 */
@Injectable({ providedIn: 'root' })
export class EconomicsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `economicsControllerGetEnergyPrices()` */
  static readonly EconomicsControllerGetEnergyPricesPath = '/planning-projects/v1-preview/economics/energy-prices/{countryCode}';

  /**
   * Gets the default values for all fuel/energy costs in current year.
   *         The records contains additionally possible price increases over the year and units of
   *         energy fuel. The prices are always related to a single unit of fuel.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `economicsControllerGetEnergyPrices()` instead.
   *
   * This method doesn't expect any request body.
   */
  economicsControllerGetEnergyPrices$Response(params: EconomicsControllerGetEnergyPrices$Params, context?: HttpContext): Observable<StrictHttpResponse<EnergyPricesResponseGetRecordsList>> {
    return economicsControllerGetEnergyPrices(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the default values for all fuel/energy costs in current year.
   *         The records contains additionally possible price increases over the year and units of
   *         energy fuel. The prices are always related to a single unit of fuel.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `economicsControllerGetEnergyPrices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  economicsControllerGetEnergyPrices(params: EconomicsControllerGetEnergyPrices$Params, context?: HttpContext): Observable<EnergyPricesResponseGetRecordsList> {
    return this.economicsControllerGetEnergyPrices$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnergyPricesResponseGetRecordsList>): EnergyPricesResponseGetRecordsList => r.body)
    );
  }

}
