/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreatePlanningResponse } from '../models/create-planning-response';
import { DeletePlanningResponse } from '../models/delete-planning-response';
import { DuplicatePlanningResponse } from '../models/duplicate-planning-response';
import { GetPlanningResponse } from '../models/get-planning-response';
import { planningsControllerCreatePlanning } from '../fn/plannings-controller/plannings-controller-create-planning';
import { PlanningsControllerCreatePlanning$Params } from '../fn/plannings-controller/plannings-controller-create-planning';
import { planningsControllerDeletePlanning } from '../fn/plannings-controller/plannings-controller-delete-planning';
import { PlanningsControllerDeletePlanning$Params } from '../fn/plannings-controller/plannings-controller-delete-planning';
import { planningsControllerDuplicatePlanning } from '../fn/plannings-controller/plannings-controller-duplicate-planning';
import { PlanningsControllerDuplicatePlanning$Params } from '../fn/plannings-controller/plannings-controller-duplicate-planning';
import { planningsControllerGetPlanning } from '../fn/plannings-controller/plannings-controller-get-planning';
import { PlanningsControllerGetPlanning$Params } from '../fn/plannings-controller/plannings-controller-get-planning';
import { planningsControllerPatchPlanning } from '../fn/plannings-controller/plannings-controller-patch-planning';
import { PlanningsControllerPatchPlanning$Params } from '../fn/plannings-controller/plannings-controller-patch-planning';
import { UpdatePlanningResponse } from '../models/update-planning-response';


/**
 * Represents an API resource for variant plannings manipulation.
 */
@Injectable({ providedIn: 'root' })
export class PlanningsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `planningsControllerCreatePlanning()` */
  static readonly PlanningsControllerCreatePlanningPath = '/planning-projects/v1-preview/projects/variants/plannings';

  /**
   * Creates new planning for variant. Planning contains a planning
   *         tools data related to products selection. One planning relates to only one planning tool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerCreatePlanning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerCreatePlanning$Response(params: PlanningsControllerCreatePlanning$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatePlanningResponse>> {
    return planningsControllerCreatePlanning(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new planning for variant. Planning contains a planning
   *         tools data related to products selection. One planning relates to only one planning tool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerCreatePlanning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerCreatePlanning(params: PlanningsControllerCreatePlanning$Params, context?: HttpContext): Observable<CreatePlanningResponse> {
    return this.planningsControllerCreatePlanning$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatePlanningResponse>): CreatePlanningResponse => r.body)
    );
  }

  /** Path part for operation `planningsControllerGetPlanning()` */
  static readonly PlanningsControllerGetPlanningPath = '/planning-projects/v1-preview/projects/variants/plannings/{id}';

  /**
   * Returns a planning by given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerGetPlanning()` instead.
   *
   * This method doesn't expect any request body.
   */
  planningsControllerGetPlanning$Response(params: PlanningsControllerGetPlanning$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPlanningResponse>> {
    return planningsControllerGetPlanning(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a planning by given ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerGetPlanning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planningsControllerGetPlanning(params: PlanningsControllerGetPlanning$Params, context?: HttpContext): Observable<GetPlanningResponse> {
    return this.planningsControllerGetPlanning$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPlanningResponse>): GetPlanningResponse => r.body)
    );
  }

  /** Path part for operation `planningsControllerDeletePlanning()` */
  static readonly PlanningsControllerDeletePlanningPath = '/planning-projects/v1-preview/projects/variants/plannings/{id}';

  /**
   * Removes an existing planning from project variant by it's ID.
   *         Planning contains a planning tools data related to products selection. One planning
   *         relates to only one planning tool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerDeletePlanning()` instead.
   *
   * This method doesn't expect any request body.
   */
  planningsControllerDeletePlanning$Response(params: PlanningsControllerDeletePlanning$Params, context?: HttpContext): Observable<StrictHttpResponse<DeletePlanningResponse>> {
    return planningsControllerDeletePlanning(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes an existing planning from project variant by it's ID.
   *         Planning contains a planning tools data related to products selection. One planning
   *         relates to only one planning tool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerDeletePlanning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planningsControllerDeletePlanning(params: PlanningsControllerDeletePlanning$Params, context?: HttpContext): Observable<DeletePlanningResponse> {
    return this.planningsControllerDeletePlanning$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeletePlanningResponse>): DeletePlanningResponse => r.body)
    );
  }

  /** Path part for operation `planningsControllerPatchPlanning()` */
  static readonly PlanningsControllerPatchPlanningPath = '/planning-projects/v1-preview/projects/variants/plannings/{id}';

  /**
   * Partially updates a planning with given ID, using partial
   *         planning structure. Please remember that the `products` property is an array
   *         and therefore patching will completely replace the `products` property with patch value.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerPatchPlanning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerPatchPlanning$Response(params: PlanningsControllerPatchPlanning$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdatePlanningResponse>> {
    return planningsControllerPatchPlanning(this.http, this.rootUrl, params, context);
  }

  /**
   * Partially updates a planning with given ID, using partial
   *         planning structure. Please remember that the `products` property is an array
   *         and therefore patching will completely replace the `products` property with patch value.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerPatchPlanning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerPatchPlanning(params: PlanningsControllerPatchPlanning$Params, context?: HttpContext): Observable<UpdatePlanningResponse> {
    return this.planningsControllerPatchPlanning$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdatePlanningResponse>): UpdatePlanningResponse => r.body)
    );
  }

  /** Path part for operation `planningsControllerDuplicatePlanning()` */
  static readonly PlanningsControllerDuplicatePlanningPath = '/planning-projects/v1-preview/projects/variants/plannings/{id}/duplicate';

  /**
   * Duplicates the planning to the desired variant.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerDuplicatePlanning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerDuplicatePlanning$Response(params: PlanningsControllerDuplicatePlanning$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicatePlanningResponse>> {
    return planningsControllerDuplicatePlanning(this.http, this.rootUrl, params, context);
  }

  /**
   * Duplicates the planning to the desired variant.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerDuplicatePlanning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerDuplicatePlanning(params: PlanningsControllerDuplicatePlanning$Params, context?: HttpContext): Observable<DuplicatePlanningResponse> {
    return this.planningsControllerDuplicatePlanning$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicatePlanningResponse>): DuplicatePlanningResponse => r.body)
    );
  }

}
