import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    BuildingPlacement,
    BuildingType,
    HeatLoadCalculationMethod,
    EnergyStandard,
    FormKeys,
    ProjectType,
    RoofType,
    WindowType,
    WindowPercentageArea,
    CountryCode,
    ConsumptionProfile
} from '@customer-apps/shared/enums';
import {
    EnergyConsumptionOptions,
    FormOptions,
    HeatGeneratorDetailsOptions,
    RoofDimensionsOptions,
    ModernizationAndInsultaionOptions,
    QuestionOptions,
    CalculationMethodOptions,
    EnergySourceOptions,
    RadioQuestionOverride
} from '@customer-apps/shared/interfaces';
import { CommonFormService } from '../../services/common-form.service';
import {
    calculationMethodValidator,
    CheckboxQuestion,
    CheckboxSet,
    CustomAnswerRadio,
    MultiTextbox,
    RadioQuestion,
    SliderQuestion,
    TextboxQuestion,
    windowTypeToOrder,
    windowTypeToPhrase,
    Question
} from '@customer-apps/shared/utils';
import { FormToolsService, ProjectsService, ValidatorsService } from '../../services';
import { RollOutPolicy } from '../../policies';

@Injectable({
    providedIn: 'root'
})
export class HeatloadFormService {
    constructor(
        private validatorsService: ValidatorsService,
        private formToolsService: FormToolsService,
        private translateService: TranslateService,
        private projectsService: ProjectsService,
        private rollOutPolicy: RollOutPolicy,
        private commonFormService: CommonFormService
    ) {}

    private calculationMethodQuestion(options: CalculationMethodOptions): CustomAnswerRadio {
        const definedValueOverride = options?.questions?.[FormKeys.DefinedValue];
        const standardMethodOverride = options?.questions?.[FormKeys.StandardMethod];
        const alreadyCalculatedMethods: HeatLoadCalculationMethod[] | undefined = options.alreadyCalculatedMethods;

        if (!options.countryCode) {
            throw Error('Please specify countryCode for calculation method question.');
        }

        const livingSpaceOption = {
            value: HeatLoadCalculationMethod.LivingSpace,
            key: 'HEATLOAD.CALCULATION_METHOD.CALC_BY_LIVING_AREA',
            disabled: this.isLivingSpaceOptionDisabled(alreadyCalculatedMethods, options.countryCode, options.projectType!)
        };
        const consumptionOption = {
            value: HeatLoadCalculationMethod.Consumption,
            key: 'HEATLOAD.CALCULATION_METHOD.CALC_BY_CONSUMPTION',
            disabled: alreadyCalculatedMethods?.length
                ? alreadyCalculatedMethods.some(method => method === HeatLoadCalculationMethod.Consumption)
                : false
        };
        const buildingHullOption = {
            value: HeatLoadCalculationMethod.BuildingHull,
            key: 'HEATLOAD.CALCULATION_METHOD.CALC_BY_BUILDING_HULL',
            disabled: this.isBuildingHullOptionDisabled(alreadyCalculatedMethods, options.countryCode, options.projectType!)
        };
        const energyCertificateOption = {
            value: HeatLoadCalculationMethod.EnergyCertificate,
            key: 'HEATLOAD.CALCULATION_METHOD.CALC_BY_ENERGY_CERT',
            disabled: alreadyCalculatedMethods?.length
                ? alreadyCalculatedMethods.some(method => method === HeatLoadCalculationMethod.EnergyCertificate)
                : false
        };

        const newConstructionOptions = [livingSpaceOption, energyCertificateOption, buildingHullOption];
        const renovationOptions = [livingSpaceOption, consumptionOption, buildingHullOption];

        const standardMethodOptions: QuestionOptions<string> = {
            key: FormKeys.StandardMethod,
            value: standardMethodOverride?.value,
            disabled: standardMethodOverride?.disabled,
            options: options.projectType === ProjectType.Renovation ? renovationOptions : newConstructionOptions
        };

        const standardMethods = new RadioQuestion(standardMethodOptions);

        if (!options.buildingType) {
            throw Error('Please specify buildingType for calculation method question.');
        }

        const maskSettings = {
            [BuildingType.SingleFamilyHouse]: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '1' }),
            [BuildingType.MultiFamilyHouse]: this.formToolsService.getNumericMask({ separatorLimit: '1000', numberOfDecimals: '1' })
        };

        const selectedMask = maskSettings[options.buildingType];
        const isDefinedValueOptionsDisabled = alreadyCalculatedMethods?.length
            ? alreadyCalculatedMethods.some(method => method === HeatLoadCalculationMethod.CustomValue)
            : false;

        const minMaxSettings = {
            [BuildingType.SingleFamilyHouse]: this.validatorsService.minMaxValidator({ min: 0, max: 50 }),
            [BuildingType.MultiFamilyHouse]: this.validatorsService.minMaxValidator({ min: 10, max: 2000 })
        };

        const selectedMinMaxValidator = minMaxSettings[options.buildingType];

        const definedValueOptions: QuestionOptions<string> = {
            key: FormKeys.DefinedValue,
            value: definedValueOverride?.value,
            styles: 'vp-form-field-medium',
            placeholder: isDefinedValueOptionsDisabled
                ? 'HEATLOAD.CALCULATION_METHOD.CALC_BY_OWN_VALUE'
                : 'HEATLOAD.CALCULATION_METHOD.CALC_BY_ENTER_OWN_VALUE',
            mask: selectedMask,
            suffix: 'MEASUREMENT_UNITS.KW',
            disabled: isDefinedValueOptionsDisabled,
            validators: [selectedMinMaxValidator],
            permanentHint: 'HEATLOAD.CALCULATION_METHOD.CALC_BY_ENTER_OWN_VALUE_HINT'
        };

        const definedValue = new TextboxQuestion(definedValueOptions);

        return new CustomAnswerRadio({
            groupName: FormKeys.Methods,
            styles: 'mb-4 vp-custom-answer-radio',
            validators: [calculationMethodValidator],
            questions: [standardMethods, definedValue]
        });
    }

    private isLivingSpaceOptionDisabled(
        alreadyCalculatedMethods: HeatLoadCalculationMethod[] | undefined,
        countryCode: CountryCode,
        projectType: ProjectType
    ): boolean {
        const isAlreadyCalculated = alreadyCalculatedMethods?.some(method => method === HeatLoadCalculationMethod.LivingSpace);
        return (
            isAlreadyCalculated ||
            (this.projectsService.isRenovation(projectType) && !this.rollOutPolicy.hasLivingAreaCalcuatiion(countryCode))
        );
    }

    private isBuildingHullOptionDisabled(
        alreadyCalculatedMethods: HeatLoadCalculationMethod[] | undefined,
        countryCode: CountryCode,
        projectType: ProjectType
    ): boolean {
        const isAlreadyCalculated = alreadyCalculatedMethods?.some(method => method === HeatLoadCalculationMethod.BuildingHull);

        return (
            isAlreadyCalculated ||
            (this.projectsService.isNewConstruction(projectType) && !this.rollOutPolicy.hasBuildingHullCalculation(countryCode))
        );
    }

    private getLivingArea(options: FormOptions): SliderQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for livingArea question.');
        }
        const settings = {
            [BuildingType.MultiFamilyHouse]: { min: 200, max: 99999 },
            [BuildingType.SingleFamilyHouse]: { min: 100, max: 450 }
        };
        const selectedSettings = settings[options.buildingType];
        const question = this.translateService.instant('HEATLOAD.LIVING_AREA.SLIDER_DESCRIPTION', selectedSettings);
        return new SliderQuestion({
            styles: 'vp-living-area-slider',
            question,
            placeholder: 'HEATLOAD.LIVING_AREA.SLIDER_PLACEHOLDER',
            key: FormKeys.LivingArea,
            validators: [Validators.required],
            min: selectedSettings.min,
            max: selectedSettings.max,
            suffix: 'MEASUREMENT_UNITS.M2',
            value: options?.questions?.[FormKeys.LivingArea]?.value || ''
        });
    }

    private getEnergyStandard(options?: FormOptions): [RadioQuestion] {
        return [
            new RadioQuestion({
                question: 'HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.TITLE',
                key: FormKeys.EnergyStandards,
                styles: 'vp-radio-columns',
                defaultErrorMessage: 'HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.ERRORS.REQUIRED',
                validators: [Validators.required],
                options: [
                    { key: 'HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.ENEV2016', value: EnergyStandard.EnEv2016 },
                    { key: 'HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.KFW55', value: EnergyStandard.KfW55 },
                    { key: 'HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.KFW40', value: EnergyStandard.KfW40 },
                    { key: 'HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.KFW40PLUS', value: EnergyStandard.KfW40Plus }
                ],
                value: options?.questions?.[FormKeys.EnergyStandards]?.value || ''
            })
        ];
    }

    private energyCertificate(options?: FormOptions): TextboxQuestion[] {
        const enclosureArea = new TextboxQuestion({
            question: 'HEATLOAD.ENERGY_CERTIFICATE.ENCLOSURE_AREA.TITLE',
            description: 'HEATLOAD.ENERGY_CERTIFICATE.ENCLOSURE_AREA.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.ENERGY_CERTIFICATE.ENCLOSURE_AREA.ERRORS.REQUIRED',
            label: 'HEATLOAD.ENERGY_CERTIFICATE.ENCLOSURE_AREA.LABEL',
            key: FormKeys.SurroundingArea,
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10000', numberOfDecimals: '2' }),
            hints: [this.formToolsService.getNumericHint()],
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 200, max: 20000 })],
            suffix: 'MEASUREMENT_UNITS.M2',
            value: options?.questions?.[FormKeys.SurroundingArea]?.value || ''
        });

        const buildingVolume = new TextboxQuestion({
            question: 'HEATLOAD.ENERGY_CERTIFICATE.BUILDING_VOLUME.TITLE',
            description: 'HEATLOAD.ENERGY_CERTIFICATE.BUILDING_VOLUME.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.ENERGY_CERTIFICATE.BUILDING_VOLUME.ERRORS.REQUIRED',
            label: 'HEATLOAD.ENERGY_CERTIFICATE.BUILDING_VOLUME.LABEL',
            key: FormKeys.BuildingVolume,
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10000', numberOfDecimals: '2' }),
            hints: [this.formToolsService.getNumericHint()],
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 200, max: 20000 })],
            suffix: 'MEASUREMENT_UNITS.M3',
            value: options?.questions?.[FormKeys.BuildingVolume]?.value || ''
        });

        if (!options?.countryCode) {
            throw Error('Please specify countryCode for air exchange rate question.');
        }

        const defaultAirExchangeRate = this.rollOutPolicy.getDefaultAirExchangeRate(options.countryCode);
        const airExchangeRate = new TextboxQuestion({
            question: 'HEATLOAD.ENERGY_CERTIFICATE.AIR_EXCH_RATE.TITLE',
            description: 'HEATLOAD.ENERGY_CERTIFICATE.AIR_EXCH_RATE.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.ENERGY_CERTIFICATE.AIR_EXCH_RATE.ERRORS.REQUIRED',
            label: 'HEATLOAD.ENERGY_CERTIFICATE.AIR_EXCH_RATE.LABEL',
            key: FormKeys.AirExchangeRate,
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
            hints: [this.formToolsService.getNumericHint()],
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0.1, max: 10 })],
            suffix: 'MEASUREMENT_UNITS.1_H',
            tooltip: defaultAirExchangeRate ? 'HEATLOAD.ENERGY_CERTIFICATE.AIR_EXCH_RATE.TOOLTIP' : '',
            value: defaultAirExchangeRate || options?.questions?.[FormKeys.AirExchangeRate]?.value || ''
        });

        const heatLoss = new TextboxQuestion({
            question: 'HEATLOAD.ENERGY_CERTIFICATE.HEAT_LOSS.TITLE',
            description: 'HEATLOAD.ENERGY_CERTIFICATE.HEAT_LOSS.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.ENERGY_CERTIFICATE.HEAT_LOSS.ERRORS.REQUIRED',
            label: 'HEATLOAD.ENERGY_CERTIFICATE.HEAT_LOSS.LABEL',
            mask: this.formToolsService.getNumericMask({ separatorLimit: '1', numberOfDecimals: '2' }),
            hints: [this.formToolsService.getNumericHint()],
            key: FormKeys.HeatLossTransmission,
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0.05, max: 5 })],
            value: options?.questions?.[FormKeys.HeatLossTransmission]?.value || ''
        });

        return [enclosureArea, buildingVolume, airExchangeRate, heatLoss];
    }

    private buildingDimensions(options: FormOptions): MultiTextbox {
        return new MultiTextbox({
            question: 'HEATLOAD.BUILDING.DIMENSIONS.TITLE',
            bottomDescription: 'HEATLOAD.BUILDING.DIMENSIONS.NOTE',
            descriptionList: [
                'HEATLOAD.BUILDING.DIMENSIONS.FIRST_BULLET',
                'HEATLOAD.BUILDING.DIMENSIONS.SECOND_BULLET',
                'HEATLOAD.BUILDING.DIMENSIONS.THIRD_BULLET'
            ],
            groupName: FormKeys.BuildingDimensions,
            styles: 'vp-form-field-columns',
            questions: [
                this.buildingLength(options),
                this.buildingWidth(options),
                this.buildingFloorHeight(options),
                this.buildingFloorsNumber(options)
            ]
        });
    }

    private buildingLength(options: FormOptions): TextboxQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for buildingLength question.');
        }

        const settings = {
            [BuildingType.SingleFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 2, max: 50 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' })
            },
            [BuildingType.MultiFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 10, max: 999 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '100', numberOfDecimals: '2' })
            }
        };

        const selectedSettings = settings[options.buildingType];

        return new TextboxQuestion({
            label: 'HEATLOAD.BUILDING.DIMENSIONS.BUILDING_LENGTH.LABEL',
            defaultErrorMessage: 'HEATLOAD.BUILDING.DIMENSIONS.BUILDING_LENGTH.ERRORS.REQUIRED',
            key: FormKeys.BuildingLength,
            validators: [Validators.required, selectedSettings.minMaxValidator],
            mask: selectedSettings.mask,
            suffix: 'MEASUREMENT_UNITS.M',
            styles: 'with-wrapped-error-message',
            value: options?.questions?.[FormKeys.BuildingLength]?.value || ''
        });
    }

    private buildingWidth(options: FormOptions): TextboxQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for buildingWidth question.');
        }

        const settings = {
            [BuildingType.SingleFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 2, max: 50 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' })
            },
            [BuildingType.MultiFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 10, max: 999 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '100', numberOfDecimals: '2' })
            }
        };

        const selectedSettings = settings[options.buildingType];

        return new TextboxQuestion({
            label: 'HEATLOAD.BUILDING.DIMENSIONS.BUILDING_WIDTH.LABEL',
            defaultErrorMessage: 'HEATLOAD.BUILDING.DIMENSIONS.BUILDING_WIDTH.ERRORS.REQUIRED',
            key: FormKeys.BuildingWidth,
            validators: [Validators.required, selectedSettings.minMaxValidator],
            mask: selectedSettings.mask,
            suffix: 'MEASUREMENT_UNITS.M',
            styles: 'with-wrapped-error-message',
            value: options?.questions?.[FormKeys.BuildingWidth]?.value || ''
        });
    }

    private buildingFloorHeight(options: FormOptions): TextboxQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for buildingFloorHeight question.');
        }

        const settings = {
            [BuildingType.SingleFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 1.5, max: 15 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' })
            },
            [BuildingType.MultiFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 1.5, max: 5 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '1', numberOfDecimals: '2' })
            }
        };

        const selectedSettings = settings[options.buildingType];

        return new TextboxQuestion({
            label: 'HEATLOAD.BUILDING.DIMENSIONS.FLOOR_HEIGHT.LABEL',
            key: FormKeys.FloorHeight,
            defaultErrorMessage: 'HEATLOAD.BUILDING.DIMENSIONS.FLOOR_HEIGHT.ERRORS.REQUIRED',
            validators: [Validators.required, selectedSettings.minMaxValidator],
            mask: selectedSettings.mask,
            suffix: 'MEASUREMENT_UNITS.M',
            styles: 'with-wrapped-error-message',
            value: options?.questions?.[FormKeys.FloorHeight]?.value || '2.55'
        });
    }

    private buildingFloorsNumber(options: FormOptions): TextboxQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for buildingFloorsNumber question.');
        }

        const settings = {
            [BuildingType.SingleFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 1, max: 5 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '1' })
            },
            [BuildingType.MultiFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 1, max: 50 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '10' })
            }
        };

        const selectedSettings = settings[options.buildingType];

        return new TextboxQuestion({
            label: 'HEATLOAD.BUILDING.DIMENSIONS.FLOORS_NUMBER.LABEL',
            key: FormKeys.NumberOfFloors,
            defaultErrorMessage: 'HEATLOAD.BUILDING.DIMENSIONS.FLOORS_NUMBER.ERRORS.REQUIRED',
            validators: [Validators.required, selectedSettings.minMaxValidator],
            mask: selectedSettings.mask,
            styles: 'with-wrapped-error-message',
            value: options?.questions?.[FormKeys.NumberOfFloors]?.value || ''
        });
    }

    private getBuildingPosition(options: FormOptions): RadioQuestion {
        return new RadioQuestion({
            question: 'HEATLOAD.BUILDING.POSITION.TITLE',
            description: 'HEATLOAD.BUILDING.POSITION.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.BUILDING.POSITION.ERRORS.REQUIRED',
            key: FormKeys.BuildingPosition,
            validators: [Validators.required],
            styles: 'vp-radio-columns',
            options: [
                { key: 'HEATLOAD.BUILDING.POSITION.FREE_STANDING', value: BuildingPlacement.Detached },
                { key: 'HEATLOAD.BUILDING.POSITION.MIDDLE_HOUSE', value: BuildingPlacement.Middle },
                { key: 'HEATLOAD.BUILDING.POSITION.CORNER_HOUSE', value: BuildingPlacement.Corner }
            ],
            value: options?.questions?.[FormKeys.BuildingPosition]?.value
        });
    }

    private getVentilationAndHeating(options: FormOptions): CheckboxSet {
        return new CheckboxSet({
            question: 'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.TITLE',
            descriptionList: [
                'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.FIRST_BULLET',
                'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.SECOND_BULLET'
            ],
            groupName: FormKeys.VentilationAndHeating,
            styles: 'd-flex flex-column vp-ventilation-and-heating',
            questions: [
                new CheckboxQuestion({
                    key: FormKeys.VentilationPresent,
                    label: 'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.VENTILATION_PRESENT',
                    value: options?.questions?.[FormKeys.VentilationPresent]?.value
                }),
                new CheckboxQuestion({
                    key: FormKeys.AtticPresent,
                    label: 'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.ATTIC_PRESENT',
                    value: options?.questions?.[FormKeys.AtticPresent]?.value
                }),
                new CheckboxQuestion({
                    key: FormKeys.AtticHeated,
                    label: 'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.ATTIC_HEATED',
                    disabled: !options?.questions?.[FormKeys.AtticPresent]?.value,
                    value: options?.questions?.[FormKeys.AtticHeated]?.value
                }),
                new CheckboxQuestion({
                    key: FormKeys.BasementPresent,
                    label: 'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.BASEMENT_PRESENT',
                    value: options?.questions?.[FormKeys.BasementPresent]?.value
                }),
                new CheckboxQuestion({
                    key: FormKeys.BasementHeated,
                    label: 'HEATLOAD.BUILDING.VENTILATION_AND_HEATING.BASEMENT_HEATED',
                    disabled: !options?.questions?.[FormKeys.BasementPresent]?.value,
                    value: options?.questions?.[FormKeys.BasementHeated]?.value
                })
            ]
        });
    }

    private getRoofType(options?: FormOptions): [RadioQuestion] {
        return [
            new RadioQuestion({
                description: 'HEATLOAD.ROOF_DIMENSIONS.ROOF_TYPE.DESCRIPTION',
                key: FormKeys.RoofType,
                validators: [Validators.required],
                styles: 'vp-radio-no-circle vp-radio-thick vp-radio-columns',
                options: [
                    { key: 'HEATLOAD.ROOF_DIMENSIONS.ROOF_TYPE.FLAT', value: RoofType.Flat, img: 'assets/images/flat-roof.png' },
                    { key: 'HEATLOAD.ROOF_DIMENSIONS.ROOF_TYPE.PENT', value: RoofType.SinglePitch, img: 'assets/images/pent-roof.png' },
                    { key: 'HEATLOAD.ROOF_DIMENSIONS.ROOF_TYPE.GABLE', value: RoofType.Saddle, img: 'assets/images/gable-roof.png' },
                    { key: 'HEATLOAD.ROOF_DIMENSIONS.ROOF_TYPE.HIPPPED', value: RoofType.Hipped, img: 'assets/images/hipped-roof.png' },
                    {
                        key: 'HEATLOAD.ROOF_DIMENSIONS.ROOF_TYPE.HALF_HIPPPED',
                        value: RoofType.HalfHipped,
                        img: 'assets/images/half-hipped-roof.png'
                    }
                ],
                value: options?.questions?.[FormKeys.RoofType]?.value
            })
        ];
    }

    private getPentRoofDimensions(options?: FormOptions): [MultiTextbox] {
        return [
            new MultiTextbox({
                groupName: FormKeys.RoofDimensions,
                description: 'HEATLOAD.ROOF_DIMENSIONS.PENT_ROOF.DESCRIPTION',
                descriptionList: [
                    'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.INCLINATION.DESCRIPTION'
                ],
                styles: 'vp-form-field-columns',
                img: 'assets/images/pent-roof-dims.png',
                questions: [
                    new TextboxQuestion({
                        key: FormKeys.HeightOfJamb,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 10 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.HeightOfJamb]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.Inclination,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 90 })],
                        suffix: 'MEASUREMENT_UNITS.DEGREE',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.Inclination]?.value
                    })
                ]
            })
        ];
    }

    private getGableRoofDimensions(options?: FormOptions): [MultiTextbox] {
        return [
            new MultiTextbox({
                groupName: FormKeys.RoofDimensions,
                description: 'HEATLOAD.ROOF_DIMENSIONS.GABLE_ROOF.DESCRIPTION',
                descriptionList: [
                    'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.INCLINATION.DESCRIPTION'
                ],
                styles: 'vp-form-field-columns',
                img: 'assets/images/gable-roof-dims.png',
                questions: [
                    new TextboxQuestion({
                        key: FormKeys.HeightOfJamb,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 10 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.HeightOfJamb]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.Inclination,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 90 })],
                        suffix: 'MEASUREMENT_UNITS.DEGREE',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.Inclination]?.value
                    })
                ]
            })
        ];
    }

    private getHippedRoofDimensions(options?: FormOptions): [MultiTextbox] {
        return [
            new MultiTextbox({
                groupName: FormKeys.RoofDimensions,
                description: 'HEATLOAD.ROOF_DIMENSIONS.HIPPED_ROOF.DESCRIPTION',
                descriptionList: [
                    'HEATLOAD.ROOF_DIMENSIONS.INCLINATION_N1.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.INCLINATION_N2.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.DESCRIPTION'
                ],
                styles: 'vp-form-field-columns',
                img: 'assets/images/hipped-roof-dims.png',
                questions: [
                    new TextboxQuestion({
                        key: FormKeys.Inclination1,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION_N1.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION_N1.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 90 })],
                        suffix: 'MEASUREMENT_UNITS.DEGREE',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.Inclination1]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.Inclination2,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION_N2.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.INCLINATION_N2.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 90 })],
                        suffix: 'MEASUREMENT_UNITS.DEGREE',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.Inclination2]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.HeightOfJamb,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 10 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.HeightOfJamb]?.value
                    })
                ]
            })
        ];
    }

    private getHalfHippedRoofDimensions(options?: FormOptions): [MultiTextbox] {
        return [
            new MultiTextbox({
                groupName: FormKeys.RoofDimensions,
                description: 'HEATLOAD.ROOF_DIMENSIONS.HALF_HIPPED_ROOF.DESCRIPTION',
                descriptionList: [
                    'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.LENGTH.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_H1.DESCRIPTION',
                    'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_H2.DESCRIPTION'
                ],
                styles: 'vp-form-field-columns',
                img: 'assets/images/half-hipped-roof-dims.png',
                questions: [
                    new TextboxQuestion({
                        key: FormKeys.HeightOfJamb,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_OF_JAMB.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 10 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.HeightOfJamb]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.RoofLength,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.LENGTH.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.LENGTH.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0.1, max: 50 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.RoofLength]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.RoofHeight1,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_H1.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_H1.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0.1, max: 10 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.RoofHeight1]?.value
                    }),
                    new TextboxQuestion({
                        key: FormKeys.RoofHeight2,
                        label: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_H2.LABEL',
                        defaultErrorMessage: 'HEATLOAD.ROOF_DIMENSIONS.HEIGHT_H2.ERRORS.REQUIRED',
                        mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' }),
                        validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0.1, max: 10 })],
                        suffix: 'MEASUREMENT_UNITS.M',
                        styles: 'with-wrapped-error-message',
                        value: options?.questions?.[FormKeys.RoofHeight2]?.value
                    })
                ]
            })
        ];
    }

    private getWindowsArea(override?: RadioQuestionOverride): RadioQuestion {
        return new RadioQuestion({
            question: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.TITLE',
            description: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.DESCRIPTION',
            key: FormKeys.WindowsPercentageArea,
            styles: 'd-flex flex-column vp-radio-margin vp-radio-width-260',
            defaultErrorMessage: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                {
                    key: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.SMALL',
                    value: WindowPercentageArea.Small,
                    subtext: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.SMALL_SUBTEXT'
                },
                {
                    key: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.MEDIUM',
                    value: WindowPercentageArea.Medium,
                    subtext: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.MEDIUM_SUBTEXT'
                },
                {
                    key: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.LARGE',
                    value: WindowPercentageArea.Large,
                    subtext: 'HEATLOAD.WINDOWS_AND_GLAZING.PERCENTAGE_AREA.LARGE_SUBTEXT'
                }
            ],
            value: override?.value
        });
    }

    private getGlazingType(override: RadioQuestionOverride): RadioQuestion {
        const windowTypes: WindowType[] = override.options;
        const questionOptions = windowTypes
            .map(windowType => {
                return {
                    value: windowType,
                    key: windowTypeToPhrase[windowType],
                    order: windowTypeToOrder[windowType]
                };
            })
            .sort((a, b) => a.order - b.order);

        return new RadioQuestion({
            question: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.TITLE',
            description: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.DESCRIPTION',
            key: FormKeys.TypeOfGlazing,
            styles: 'd-flex flex-column vp-radio-margin vp-radio-width-400',
            defaultErrorMessage: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: questionOptions,
            value: override.value
        });
    }

    private getWindowsConstructionYear(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WINDOWS.TITLE',
            key: FormKeys.WindowsConstructionYear,
            styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center',
            defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WINDOWS.ERRORS.REQUIRED',
            paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WINDOWS.PARAGRAPH',
            placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WINDOWS.PLACEHOLDER',
            validators: [this.validatorsService.minMaxValidator({ min: 1950, max: new Date().getFullYear() })],
            mask: { pattern: '0000' },
            value: this.getRenovationYear(options?.questions?.[FormKeys.WindowsConstructionYear]?.value, options),
            permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
        });
    }

    private getRoofInsulation(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.TITLE',
            key: FormKeys.RoofInsulation,
            value: options?.questions?.[FormKeys.RoofInsulation]?.value || '0',
            styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center',
            defaultErrorMessage: 'HEATLOAD.INSULATION.ROOF.ERRORS.REQUIRED',
            paragraph: 'HEATLOAD.INSULATION.ROOF.PARAGRAPH',
            placeholder: 'HEATLOAD.INSULATION.ROOF.PLACEHOLDER',
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
            suffix: 'MEASUREMENT_UNITS.CM'
        });
    }

    private getUpperFloorInsulation(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.TITLE',
            key: FormKeys.UpperFloorInsulation,
            value: options?.questions?.[FormKeys.UpperFloorInsulation]?.value || '0',
            styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center',
            defaultErrorMessage: 'HEATLOAD.INSULATION.UPPER_FLOOR.ERRORS.REQUIRED',
            paragraph: 'HEATLOAD.INSULATION.UPPER_FLOOR.PARAGRAPH',
            placeholder: 'HEATLOAD.INSULATION.UPPER_FLOOR.PLACEHOLDER',
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
            suffix: 'MEASUREMENT_UNITS.CM'
        });
    }

    private getWallsInsulation(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.TITLE',
            key: FormKeys.WallsInsulation,
            value: options?.questions?.[FormKeys.WallsInsulation]?.value || '0',
            styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center',
            defaultErrorMessage: 'HEATLOAD.INSULATION.WALLS.ERRORS.REQUIRED',
            paragraph: 'HEATLOAD.INSULATION.WALLS.PARAGRAPH',
            placeholder: 'HEATLOAD.INSULATION.WALLS.PLACEHOLDER',
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
            suffix: 'MEASUREMENT_UNITS.CM'
        });
    }

    private getBasementInsulation(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.TITLE',
            key: FormKeys.BasementInsulation,
            value: options?.questions?.[FormKeys.BasementInsulation]?.value || '0',
            styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center',
            defaultErrorMessage: 'HEATLOAD.INSULATION.BASEMENT.ERRORS.REQUIRED',
            paragraph: 'HEATLOAD.INSULATION.BASEMENT.PARAGRAPH',
            placeholder: 'HEATLOAD.INSULATION.BASEMENT.PLACEHOLDER',
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
            suffix: 'MEASUREMENT_UNITS.CM'
        });
    }

    private getRoofRenovationAndInsulation(options?: FormOptions): MultiTextbox {
        return new MultiTextbox({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.TITLE',
            groupName: FormKeys.RoofRenovationAndInsulation,
            validators: [Validators.required],
            questions: [
                new TextboxQuestion({
                    key: FormKeys.RoofInsulation,
                    value: options?.questions?.[FormKeys.RoofInsulation]?.value || '0',
                    styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.INSULATION.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.INSULATION.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.INSULATION.PLACEHOLDER',
                    validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
                    suffix: 'MEASUREMENT_UNITS.CM'
                }),
                new TextboxQuestion({
                    key: FormKeys.RoofRenovationYear,
                    styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.YEAR.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.YEAR.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.YEAR.PLACEHOLDER',
                    validators: [this.validatorsService.minMaxValidator({ min: 1950, max: new Date().getFullYear() })],
                    mask: { pattern: '0000' },
                    value: this.getRenovationYear(options?.questions?.[FormKeys.RoofRenovationYear]?.value, options),
                    permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
                })
            ]
        });
    }

    private getUpperFloorRenovationAndInsulation(options?: FormOptions): MultiTextbox {
        return new MultiTextbox({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.TITLE',
            groupName: FormKeys.UpperFloorRenovationAndInsulation,
            validators: [Validators.required],
            questions: [
                new TextboxQuestion({
                    key: FormKeys.UpperFloorInsulation,
                    value: options?.questions?.[FormKeys.UpperFloorInsulation]?.value || '0',
                    styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.INSULATION.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.INSULATION.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.INSULATION.PLACEHOLDER',
                    validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
                    suffix: 'MEASUREMENT_UNITS.CM'
                }),
                new TextboxQuestion({
                    key: FormKeys.AtticRenovationYear,
                    styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.YEAR.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.YEAR.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.UPPER_FLOOR.YEAR.PLACEHOLDER',
                    validators: [this.validatorsService.minMaxValidator({ min: 1950, max: new Date().getFullYear() })],
                    mask: { pattern: '0000' },
                    value: this.getRenovationYear(options?.questions?.[FormKeys.AtticRenovationYear]?.value, options),
                    permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
                })
            ]
        });
    }

    private getWallsRenovationAndInsulation(options?: FormOptions): MultiTextbox {
        return new MultiTextbox({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.TITLE',
            groupName: FormKeys.WallsRenovationAndInsulation,
            validators: [Validators.required],
            questions: [
                new TextboxQuestion({
                    key: FormKeys.WallsInsulation,
                    value: options?.questions?.[FormKeys.WallsInsulation]?.value || '0',
                    styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.INSULATION.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.INSULATION.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.INSULATION.PLACEHOLDER',
                    validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
                    suffix: 'MEASUREMENT_UNITS.CM'
                }),
                new TextboxQuestion({
                    key: FormKeys.WallsRenovationYear,
                    styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.YEAR.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.YEAR.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.YEAR.PLACEHOLDER',
                    validators: [this.validatorsService.minMaxValidator({ min: 1950, max: new Date().getFullYear() })],
                    mask: { pattern: '0000' },
                    value: this.getRenovationYear(options?.questions?.[FormKeys.WallsRenovationYear]?.value, options),
                    permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
                })
            ]
        });
    }
    private getBasementRenovationAndInsulation(options?: FormOptions): MultiTextbox {
        return new MultiTextbox({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.TITLE',
            groupName: FormKeys.BasementRenovationAndInsulation,
            validators: [Validators.required],
            questions: [
                new TextboxQuestion({
                    key: FormKeys.BasementInsulation,
                    value: options?.questions?.[FormKeys.BasementInsulation]?.value || '0',
                    styles: 'vp-form-field-thin vp-form-field-w-75 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.INSULATION.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.INSULATION.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.INSULATION.PLACEHOLDER',
                    validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 0, max: 50 })],
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
                    suffix: 'MEASUREMENT_UNITS.CM'
                }),
                new TextboxQuestion({
                    key: FormKeys.BasementRenovationYear,
                    styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center with-paragraph-error-message',
                    defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.YEAR.ERRORS.REQUIRED',
                    paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.YEAR.PARAGRAPH_2',
                    placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.YEAR.PLACEHOLDER',
                    validators: [this.validatorsService.minMaxValidator({ min: 1950, max: new Date().getFullYear() })],
                    mask: { pattern: '0000' },
                    value: this.getRenovationYear(options?.questions?.[FormKeys.BasementRenovationYear]?.value, options),
                    permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
                })
            ]
        });
    }

    private getRenovationYear(year: number, options?: FormOptions): number | undefined {
        const buildingConstructionYear = options?.questions?.[FormKeys.BuildingConstructionYear]?.value;
        return year && year !== buildingConstructionYear ? year : undefined;
    }

    private getVentilation(options?: FormOptions): RadioQuestion[] {
        return [
            new RadioQuestion({
                question: 'HEATLOAD.VENTILATION.VENTILATION.TITLE',
                key: FormKeys.Ventilation,
                styles: 'vp-radio-columns',
                defaultErrorMessage: 'Please provide ventilation method',
                validators: [Validators.required],
                options: [
                    { key: 'HEATLOAD.VENTILATION.VENTILATION.YES', value: true },
                    { key: 'HEATLOAD.VENTILATION.VENTILATION.NO', value: false }
                ],
                value: options?.questions?.[FormKeys.Ventilation]?.value
            })
        ];
    }

    /*
     * Adds "by consumption" option to radio question options if project kind is "renovation".
     */
    public getCalculationMethodQuestions(options: CalculationMethodOptions): [CustomAnswerRadio] {
        const calculationMethod = this.calculationMethodQuestion(options);
        return [calculationMethod];
    }

    public getLivingAreaQuestions(options: FormOptions): SliderQuestion[] {
        const livingArea = this.getLivingArea(options);
        return [livingArea];
    }

    public getEnergyStandardQuestions(options?: FormOptions): [RadioQuestion] {
        return this.getEnergyStandard(options);
    }

    /*
     * Creates validation functions for questions and assign values to them if such are provided in options object. Returns questions for Energy Certificate form.
     */
    public getEnergyCertificateQuestions(options?: FormOptions): TextboxQuestion[] {
        const questionsClone: TextboxQuestion[] = this.energyCertificate(options);
        return questionsClone.map(question => {
            const value = options?.questions?.[question.key]?.value;
            if (value) {
                question.value = value;
            }

            return question;
        });
    }

    public getBuildingQuestions(options: FormOptions): (RadioQuestion | MultiTextbox | CheckboxSet)[] {
        const buildingDimensions = this.buildingDimensions(options);
        const buildingPosition = this.getBuildingPosition(options);
        const ventilationAndHeating = this.getVentilationAndHeating(options);

        return [buildingDimensions, buildingPosition, ventilationAndHeating];
    }

    public getRoofTypeQuestions(options?: FormOptions): (RadioQuestion | MultiTextbox | CheckboxSet)[] {
        return this.getRoofType(options);
    }

    public getRoofDimensionsQuestions(options: RoofDimensionsOptions): [MultiTextbox] {
        switch (options.roofType) {
            case RoofType.SinglePitch:
                return this.getPentRoofDimensions(options);
            case RoofType.Saddle:
                return this.getGableRoofDimensions(options);
            case RoofType.Hipped:
                return this.getHippedRoofDimensions(options);
            case RoofType.HalfHipped:
                return this.getHalfHippedRoofDimensions(options);
            default:
                throw Error('"roofType" parameter must be specified');
        }
    }

    public getWindowsAndGlazingQuestions(options: FormOptions): [RadioQuestion, RadioQuestion] {
        const typeOfGlazingOveride = options?.questions?.[FormKeys.TypeOfGlazing] as RadioQuestionOverride;
        if (!typeOfGlazingOveride?.options) {
            throw Error('Please specify options for "type of glazing" question');
        }
        const glazingType = this.getGlazingType(typeOfGlazingOveride);

        const windowsAreaOveride = options?.questions?.[FormKeys.WindowsPercentageArea] as RadioQuestionOverride;
        const windowsArea = this.getWindowsArea(windowsAreaOveride);
        return [windowsArea, glazingType];
    }

    public getModernizationQuestions(options?: FormOptions): TextboxQuestion[] {
        return this.getModernizations(options);
    }

    public getVentilationQuestions(options?: FormOptions): RadioQuestion[] {
        return this.getVentilation(options);
    }

    /**
     * Returns Heat Generator question config by default. Returns Energy Source and Required Energy Per Year questions configs if they are specified in options.
     */
    public getEnergySourceQuestions(options?: EnergySourceOptions): RadioQuestion[] {
        const heatGeneratorOverride = options?.questions?.[FormKeys.HeatGenerator] as RadioQuestionOverride;
        if (!heatGeneratorOverride?.options) {
            throw Error('Provide option keys for heat generator question');
        }
        const heatGenerator = this.commonFormService.getHeatGenerator(heatGeneratorOverride, {
            heatGeneratorDictionary: options?.heatGeneratorDictionary
        });
        if (heatGeneratorOverride) {
            heatGenerator.value = heatGeneratorOverride.value;
        }
        const questions: RadioQuestion[] = [heatGenerator];
        const specificEnergySourceOveride = options?.questions?.[FormKeys.EnergySource] as RadioQuestionOverride;

        if (specificEnergySourceOveride?.options) {
            const specificEnergySource = this.commonFormService.getSpecificEnergySource(specificEnergySourceOveride);
            specificEnergySource.value = specificEnergySourceOveride.value;
            questions.push(specificEnergySource);
        }
        return questions;
    }

    public getHeatGeneratorDetailsQuestions(options?: HeatGeneratorDetailsOptions): Question[] {
        const installationYear = this.commonFormService.getInstallationYear(options);

        if (!options) {
            return [installationYear];
        }
        const questions: Question[] = [];

        const installationYearOverride = options.questions?.[FormKeys.InstallationYear];
        if (installationYearOverride) {
            installationYear.value = installationYearOverride.value || options.buildingConstructionYear;
        }
        questions.push(installationYear);

        const boilerTypeOverride = options.questions?.[FormKeys.BoilerType] as RadioQuestionOverride;

        if (boilerTypeOverride && !boilerTypeOverride.options) {
            throw Error('Boiler type question must have specified options property');
        }

        if (boilerTypeOverride) {
            const boilerType = this.commonFormService.getBoilerType(boilerTypeOverride);
            boilerType.value = boilerTypeOverride.value;
            questions.push(boilerType);
        }
        const hasBoilerTypeSubquestionOverrides =
            options.questions?.[FormKeys.BoilerTypeSubquestion] ||
            options.questions?.[FormKeys.LowTemperature] ||
            options.questions?.[FormKeys.WarmWaterByHeatGenerator];
        const isBoilerTypeSubquestion =
            hasBoilerTypeSubquestionOverrides || this.formToolsService.hasBoilerOptionsToAsk(options.selectedInstallationType);

        if (isBoilerTypeSubquestion) {
            const boilerTypeSubquestion = this.commonFormService.getBoilerTypeSubquestion(options);
            questions.push(boilerTypeSubquestion);
        }

        const operatingModeOverride = options.questions?.[FormKeys.HeatPumpOperatingMode] as RadioQuestionOverride;

        if (operatingModeOverride?.options) {
            const operatingMode = this.commonFormService.getHeatPumpOperatingMode(operatingModeOverride);
            operatingMode.value = operatingModeOverride.value;
            questions.push(operatingMode);
        }

        const nominalPowerOverride = options.questions?.[FormKeys.NominalPower];
        if (nominalPowerOverride) {
            const nominalPower = this.commonFormService.getNominalPower(options);
            nominalPower.value = nominalPowerOverride.value;
            questions.push(nominalPower);
        }

        const hasGasLossExhaustKnownOverrides = options.questions?.[FormKeys.GasLossExhaustKnown] || options.questions?.[FormKeys.IsKnown];
        if (hasGasLossExhaustKnownOverrides) {
            const gasLossExhaustKnown = this.commonFormService.gasLossExhaustKnown(options);
            questions.push(gasLossExhaustKnown);
        }

        const gasLossExhaustOverride = options.questions?.[FormKeys.GasLossExhaust];
        if (gasLossExhaustOverride) {
            const gasLossExhaust = this.commonFormService.getGasLossExhaust(options);
            gasLossExhaust.value = gasLossExhaustOverride.value;
            questions.push(gasLossExhaust);
        }

        return questions;
    }

    public getEnergyConsumptionQuestions(options: EnergyConsumptionOptions): Question[] {
        const questions = [];

        if (options.selectedSource && options.selectedUnit) {
            const energyRequiredPerYear = this.commonFormService.getEnergyRequiredPerYear(options);
            const energyRequiredPerYearOverride = options?.questions?.[FormKeys.RequiredEnergyPerYear];
            energyRequiredPerYear.value = energyRequiredPerYearOverride?.value;
            questions.push(energyRequiredPerYear);
        }

        const nonHeatingElectricityPerYear = this.commonFormService.nonHeatingElectricityConsumption(options);
        const nonHeatingElectricityPerYearOverride = options?.questions?.[FormKeys.NonHeatingElectricityPerYear];
        nonHeatingElectricityPerYear.value = nonHeatingElectricityPerYearOverride?.value;
        questions.push(nonHeatingElectricityPerYear);

        return questions;
    }

    public getHeatingDistributionQuestions(options?: FormOptions): (RadioQuestion | TextboxQuestion)[] {
        const distributionMethod = this.commonFormService.getDistributionMethod(options);
        const distributionMethodOverride = options?.questions?.[FormKeys.DistributionMethod];
        distributionMethod.value = distributionMethodOverride?.value || distributionMethod.value;

        const heatingFlowTemperature = this.commonFormService.getHeatingFlowTemperature(options);
        const heatingFlowTemperatureOverride = options?.questions?.[FormKeys.HeatingFlowTemperature];
        heatingFlowTemperature.value = heatingFlowTemperatureOverride?.value || heatingFlowTemperature.value;

        const questions = [distributionMethod, heatingFlowTemperature];

        const heatingScheduleOverride = options?.questions?.[FormKeys.HeatingSchedule] as RadioQuestionOverride;

        if (!heatingScheduleOverride?.options) {
            throw Error('Please specify options for heating schedule question');
        }

        if (
            options?.projectType === ProjectType.Renovation &&
            (options?.heatLoadCalculationMethod === HeatLoadCalculationMethod.LivingSpace ||
                options?.heatLoadCalculationMethod === HeatLoadCalculationMethod.CustomValue ||
                options?.heatLoadCalculationMethod === HeatLoadCalculationMethod.BuildingHull)
        ) {
            heatingScheduleOverride.value = ConsumptionProfile.NocturnalFall;
        }

        const heatingSchedule = this.commonFormService.getHeatingSchedule(heatingScheduleOverride);
        heatingSchedule.value = heatingScheduleOverride?.value || heatingSchedule.value;

        questions.push(heatingSchedule);

        return questions;
    }

    public getModernizationsAndInsulationQuestions(options: ModernizationAndInsultaionOptions): Question[] {
        const insulationQuestions = [
            this.getRoofInsulation(options),
            this.getUpperFloorInsulation(options),
            this.getWallsInsulation(options)
        ];

        if (options.basementPresent) {
            insulationQuestions.push(this.getBasementInsulation(options));
        }

        if (options.projectType === ProjectType.NewConstruction) {
            return insulationQuestions;
        }

        const renovationAndInsulationQuestions = [
            this.commonFormService.getBuildingConstructionYear(options),
            this.getWindowsConstructionYear(options),
            this.getRoofRenovationAndInsulation(options),
            this.getUpperFloorRenovationAndInsulation(options),
            this.getWallsRenovationAndInsulation(options)
        ];

        if (options.basementPresent) {
            renovationAndInsulationQuestions.push(this.getBasementRenovationAndInsulation(options));
        }
        return renovationAndInsulationQuestions;
    }

    private getModernizations(options?: FormOptions): TextboxQuestion[] {
        const maxYear = new Date().getFullYear();
        return [
            new TextboxQuestion({
                question: 'HEATLOAD.MODERNIZATIONS.BUILDING.TITLE',
                key: FormKeys.BuildingConstructionYear,
                styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center with-wrapped-error-message-350',
                defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS.BUILDING.ERRORS.REQUIRED',
                paragraph: 'HEATLOAD.MODERNIZATIONS.BUILDING.PARAGRAPH',
                placeholder: 'HEATLOAD.MODERNIZATIONS.BUILDING.PLACEHOLDER',
                validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 1950, max: maxYear })],
                mask: { pattern: '0000' },
                value: options?.questions?.[FormKeys.BuildingConstructionYear]?.value || ''
            }),
            new TextboxQuestion({
                question: 'HEATLOAD.MODERNIZATIONS.ROOF.TITLE',
                key: FormKeys.RoofModernization,
                styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center',
                defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS.ROOF.ERRORS.REQUIRED',
                paragraph: 'HEATLOAD.MODERNIZATIONS.ROOF.PARAGRAPH',
                placeholder: 'HEATLOAD.MODERNIZATIONS.ROOF.PLACEHOLDER',
                validators: [this.validatorsService.minMaxValidator({ min: 1950, max: maxYear })],
                mask: { pattern: '0000' },
                value: this.getRenovationYear(options?.questions?.[FormKeys.RoofModernization]?.value, options),
                permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
            }),
            new TextboxQuestion({
                question: 'HEATLOAD.MODERNIZATIONS.WALL.TITLE',
                key: FormKeys.WallsModernization,
                styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center',
                defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS.WALL.ERRORS.REQUIRED',
                paragraph: 'HEATLOAD.MODERNIZATIONS.WALL.PARAGRAPH',
                placeholder: 'HEATLOAD.MODERNIZATIONS.WALL.PLACEHOLDER',
                validators: [this.validatorsService.minMaxValidator({ min: 1950, max: maxYear })],
                mask: { pattern: '0000' },
                value: this.getRenovationYear(options?.questions?.[FormKeys.WallsModernization]?.value, options),
                permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
            }),
            new TextboxQuestion({
                question: 'HEATLOAD.MODERNIZATIONS.WINDOWS.TITLE',
                key: FormKeys.WindowsModernization,
                styles: 'vp-form-field-thin vp-form-field-w-65 vp-form-field-center',
                defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS.WINDOWS.ERRORS.REQUIRED',
                paragraph: 'HEATLOAD.MODERNIZATIONS.WINDOWS.PARAGRAPH',
                placeholder: 'HEATLOAD.MODERNIZATIONS.WINDOWS.PLACEHOLDER',
                validators: [this.validatorsService.minMaxValidator({ min: 1950, max: maxYear })],
                mask: { pattern: '0000' },
                value: this.getRenovationYear(options?.questions?.[FormKeys.WindowsModernization]?.value, options),
                permanentHint: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.RENOVATION_YEAR.HINT'
            })
        ];
    }
}
