/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateOrderResponse } from '../models/create-order-response';
import { ordersControllerCreateOrder } from '../fn/orders-controller/orders-controller-create-order';
import { OrdersControllerCreateOrder$Params } from '../fn/orders-controller/orders-controller-create-order';
import { ordersControllerGetOrders } from '../fn/orders-controller/orders-controller-get-orders';
import { OrdersControllerGetOrders$Params } from '../fn/orders-controller/orders-controller-get-orders';
import { OrdersResponseGetRecordsList } from '../models/orders-response-get-records-list';


/**
 * Represents a resource used to create, fetch and update the data related to project orders.
 */
@Injectable({ providedIn: 'root' })
export class OrdersControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ordersControllerGetOrders()` */
  static readonly OrdersControllerGetOrdersPath = '/planning-projects/v1-preview/orders';

  /**
   * Gets all order records for a given project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ordersControllerGetOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  ordersControllerGetOrders$Response(params: OrdersControllerGetOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<OrdersResponseGetRecordsList>> {
    return ordersControllerGetOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets all order records for a given project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ordersControllerGetOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ordersControllerGetOrders(params: OrdersControllerGetOrders$Params, context?: HttpContext): Observable<OrdersResponseGetRecordsList> {
    return this.ordersControllerGetOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrdersResponseGetRecordsList>): OrdersResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `ordersControllerCreateOrder()` */
  static readonly OrdersControllerCreateOrderPath = '/planning-projects/v1-preview/orders';

  /**
   * Request new order to be created in a Partner Shop tool. As a result we receive new order
   *         configuration URL for Partner Shop where we can actually place an order.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ordersControllerCreateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ordersControllerCreateOrder$Response(params: OrdersControllerCreateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateOrderResponse>> {
    return ordersControllerCreateOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Request new order to be created in a Partner Shop tool. As a result we receive new order
   *         configuration URL for Partner Shop where we can actually place an order.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ordersControllerCreateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ordersControllerCreateOrder(params: OrdersControllerCreateOrder$Params, context?: HttpContext): Observable<CreateOrderResponse> {
    return this.ordersControllerCreateOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateOrderResponse>): CreateOrderResponse => r.body)
    );
  }

}
