/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConsumptionProfilesResponseGetRecordsList } from '../../models/consumption-profiles-response-get-records-list';

export interface BuildingsControllerConsumptionProfiles$Params {

/**
 * Type of the building.
 */
  buildingType: 'ONE_FAMILY_HOUSE' | 'MULTI_FAMILY_HOUSE';

/**
 * ISO standard Alpha-2 country code.The building address country code.
 */
  countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

/**
 * The type of the ViPlan project - it may be a modernization or new building.
 */
  projectType: 'MODERNISATION' | 'NEW_BUILDING';
}

export function buildingsControllerConsumptionProfiles(http: HttpClient, rootUrl: string, params: BuildingsControllerConsumptionProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsumptionProfilesResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, buildingsControllerConsumptionProfiles.PATH, 'get');
  if (params) {
    rb.query('buildingType', params.buildingType, {});
    rb.query('countryCode', params.countryCode, {});
    rb.query('projectType', params.projectType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConsumptionProfilesResponseGetRecordsList>;
    })
  );
}

buildingsControllerConsumptionProfiles.PATH = '/planning-projects/v1-preview/buildings/consumption-profiles';
