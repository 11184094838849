/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressValidationResponseGetRecordsList } from '../models/address-validation-response-get-records-list';
import { CitiesResponseGetRecordsList } from '../models/cities-response-get-records-list';
import { CountriesResponseGetRecordsList } from '../models/countries-response-get-records-list';
import { locationsControllerGetCities } from '../fn/locations-controller/locations-controller-get-cities';
import { LocationsControllerGetCities$Params } from '../fn/locations-controller/locations-controller-get-cities';
import { locationsControllerGetCountries } from '../fn/locations-controller/locations-controller-get-countries';
import { LocationsControllerGetCountries$Params } from '../fn/locations-controller/locations-controller-get-countries';
import { locationsControllerGetOrganizations } from '../fn/locations-controller/locations-controller-get-organizations';
import { LocationsControllerGetOrganizations$Params } from '../fn/locations-controller/locations-controller-get-organizations';
import { locationsControllerValidateAddress } from '../fn/locations-controller/locations-controller-validate-address';
import { LocationsControllerValidateAddress$Params } from '../fn/locations-controller/locations-controller-validate-address';
import { OrganizationsResponseGetRecordsList } from '../models/organizations-response-get-records-list';


/**
 * Represents an API resource used to get the data related to addresses and locations.
 */
@Injectable({ providedIn: 'root' })
export class LocationsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `locationsControllerGetCities()` */
  static readonly LocationsControllerGetCitiesPath = '/planning-projects/v1-preview/locations/cities';

  /**
   * Returns a set of cities matching request country and postal code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetCities()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCities$Response(params: LocationsControllerGetCities$Params, context?: HttpContext): Observable<StrictHttpResponse<CitiesResponseGetRecordsList>> {
    return locationsControllerGetCities(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a set of cities matching request country and postal code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetCities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCities(params: LocationsControllerGetCities$Params, context?: HttpContext): Observable<CitiesResponseGetRecordsList> {
    return this.locationsControllerGetCities$Response(params, context).pipe(
      map((r: StrictHttpResponse<CitiesResponseGetRecordsList>): CitiesResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `locationsControllerGetCountries()` */
  static readonly LocationsControllerGetCountriesPath = '/planning-projects/v1-preview/locations/countries';

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCountries$Response(params?: LocationsControllerGetCountries$Params, context?: HttpContext): Observable<StrictHttpResponse<CountriesResponseGetRecordsList>> {
    return locationsControllerGetCountries(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCountries(params?: LocationsControllerGetCountries$Params, context?: HttpContext): Observable<CountriesResponseGetRecordsList> {
    return this.locationsControllerGetCountries$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountriesResponseGetRecordsList>): CountriesResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `locationsControllerGetOrganizations()` */
  static readonly LocationsControllerGetOrganizationsPath = '/planning-projects/v1-preview/locations/organizations';

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetOrganizations$Response(params?: LocationsControllerGetOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationsResponseGetRecordsList>> {
    return locationsControllerGetOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetOrganizations(params?: LocationsControllerGetOrganizations$Params, context?: HttpContext): Observable<OrganizationsResponseGetRecordsList> {
    return this.locationsControllerGetOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationsResponseGetRecordsList>): OrganizationsResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `locationsControllerValidateAddress()` */
  static readonly LocationsControllerValidateAddressPath = '/planning-projects/v1-preview/locations/addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerValidateAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  locationsControllerValidateAddress$Response(params: LocationsControllerValidateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressValidationResponseGetRecordsList>> {
    return locationsControllerValidateAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerValidateAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  locationsControllerValidateAddress(params: LocationsControllerValidateAddress$Params, context?: HttpContext): Observable<AddressValidationResponseGetRecordsList> {
    return this.locationsControllerValidateAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressValidationResponseGetRecordsList>): AddressValidationResponseGetRecordsList => r.body)
    );
  }

}
