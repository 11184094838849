import { Injectable } from '@angular/core';
import { map, Observable, withLatestFrom, filter, first, merge } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadFormService } from './heatload-form.service';
import { CountryCode, EnergyStandard, FormName, ProjectType } from '@customer-apps/shared/enums';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { Question } from '@customer-apps/shared/utils';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import { TranslateService } from '@ngx-translate/core';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { AccordionService } from '@customer-apps/shared/interfaces';
import { ActivatedRoute } from '@angular/router';
import { RollOutPolicy } from '../../policies';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class EnergyStandardService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormService: HeatloadFormService,
        private heatloadService: HeatloadService,
        private heatloadStore: HeatloadStore,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private rollOutPolicy: RollOutPolicy,
        private translate: TranslateService
    ) {}

    public questions$: Observable<Question[] | null> = this.heatloadStore.livingArea$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([livingArea, project, cache]) => ({
            livingArea,
            project,
            cache
        })),
        filter(({ project }) => this.projectsService.isNewConstruction(project!.projectType as ProjectType)),
        map(state => {
            if (!state.livingArea) {
                return null;
            }

            const cachePrefill = this.prefillMapper.stateToEnergyStandardPrefill(state.cache?.energyStandards);
            const projectPrefill = this.prefillMapper.projectToEnergyStandardPrefill(state.project);
            const formOptions = this.heatloadFormOptionsService.getEnergyStandardFormOptions(cachePrefill || projectPrefill);
            return this.heatloadFormService.getEnergyStandardQuestions(formOptions);
        })
    );

    public getSuccessMessage(value: EnergyStandard, project: ProjectResponseGetById): string {
        const defaultMessage = this.rollOutPolicy.getDefaultEnergyStandardSuccessMessage(project.address.countryCode as CountryCode);
        if (defaultMessage) {
            return defaultMessage;
        }

        switch (value) {
            case EnergyStandard.EnEv2016:
                return this.translate.instant('HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.ENEV2016');
            case EnergyStandard.KfW55:
                return this.translate.instant('HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.KFW55');
            case EnergyStandard.KfW40:
                return this.translate.instant('HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.KFW40');
            case EnergyStandard.KfW40Plus:
                return this.translate.instant('HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.KFW40PLUS');
            default:
                return '';
        }
    }

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.EnergyStandard);
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.EnergyStandard);
    }

    public isExpanded$ = merge(this.prefillCompletedByLivingArea$(), this.isSameValueSubmitedLivingArea$());

    private isSameValueSubmitedLivingArea$(): Observable<boolean> {
        return this.heatloadStore.sameValueSubmited$.pipe(
            withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
            map(
                ({ form, project }) =>
                    form?.formName === FormName.LivingArea && this.projectsService.isNewConstruction(project!.projectType as ProjectType)
            )
        );
    }

    private prefillCompletedByLivingArea$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            withLatestFrom(this.projectStore.project$, (prefillState, project) => ({ prefillState, project })),
            map(({ prefillState, project }) => {
                const lastAccordionToBePrefilled = this.projectsService.isNewConstruction(project!.projectType as ProjectType)
                    ? FormName.EnergyStandard
                    : FormName.Ventilation;
                return prefillState[lastAccordionToBePrefilled].isPrefilled && !this.heatloadService.isRecalculation();
            }),
            filter(Boolean),
            first()
        );
    }
}
