import { Injectable } from '@angular/core';
import { map, Observable, merge, withLatestFrom, of, EMPTY, mergeMap, distinctUntilChanged } from 'rxjs';
import { HeatloadFormService, HeatloadPrefillService, HeatloadService } from '.';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { BuildingType, FormName } from '@customer-apps/shared/enums';
import { Question } from '@customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { AccordionService, HeatGeneratorDetailsFormValue } from '@customer-apps/shared/interfaces';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { HeatloadPrefillMapper } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class HeatGeneratorDetailsService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private heatloadFormService: HeatloadFormService,
        private translate: TranslateService
    ) {}

    public questions$: Observable<Question[] | null> = merge(this.onEnergySource$(), this.onHeatingSystems$()).pipe(distinctUntilChanged());

    public getSuccessMessage(options: HeatGeneratorDetailsFormValue): string {
        const unit = this.translate.instant('MEASUREMENT_UNITS.KW');
        const installation = this.translate.instant(`HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.${options.boilerType}`);
        return `${installation}, ${options.installationYear}, ${options.nominalPower} ${unit}`;
    }

    public isPrefillNeeded(): boolean {
        return (
            this.heatloadService.isRecalculation() &&
            !this.heatloadPrefillService.isPrefilled(FormName.HeatGeneratorDetails) &&
            !this.heatloadPrefillService.isFinished(FormName.HeatGeneratorDetails)
        );
    }

    private onEnergySource$() {
        return this.heatloadStore.energySource$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
            map(([energySource, project, cache]) => ({
                energySource,
                project,
                cache
            })),
            mergeMap(state => {
                if (!state.energySource) {
                    return of(null);
                }

                const cacheInstallationYear = state.cache?.heatGeneratorDetails?.installationYear;
                const installationConstructionYear = state.project!.building.currentHeatingSystem?.installationConstructionYear;
                const buildingConstructionYear = state.project!.building?.buildingConstructionYear;
                const installationYearForHeatingSystems = cacheInstallationYear || installationConstructionYear || buildingConstructionYear;

                if (this.isPrefillNeeded() && installationYearForHeatingSystems) {
                    this.heatloadStore.getHeatingSystems(`${installationYearForHeatingSystems}`);
                    return EMPTY;
                }

                const options = this.heatloadFormOptionsService.getHeatGeneratorDetailsFormOptions({
                    buildingType: state.project!.building.buildingType as BuildingType,
                    buildingConstructionYear
                });

                const questions = this.heatloadFormService.getHeatGeneratorDetailsQuestions(options);
                return of(questions);
            })
        );
    }
    private onHeatingSystems$() {
        return this.heatloadStore.heatingSystems$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatloadStore.energySource$, this.heatloadStore.cache$),
            map(([heatingSystems, project, energySourceState, cache]) => ({
                heatingSystems,
                project,
                energySourceState,
                cache
            })),
            mergeMap(state => {
                if (!this.isPrefillNeeded()) {
                    return EMPTY;
                }

                if (!state.heatingSystems) {
                    return of(null);
                }

                const projectPrefill = this.prefillMapper.projectToHeatGeneratorDetailsPrefill(state.project);
                const cachePrefill = this.prefillMapper.stateToHeatGeneratorDetailsPrefill(state.cache?.heatGeneratorDetails);
                const options = this.heatloadFormOptionsService.getHeatGeneratorDetailsFormOptions({
                    buildingType: state.project!.building.buildingType as BuildingType,
                    prefill: cachePrefill || projectPrefill,
                    energySourceState: state.energySourceState,
                    heatingSystems: state.heatingSystems,
                    buildingConstructionYear: state.project!.building?.buildingConstructionYear
                });

                const questions = this.heatloadFormService.getHeatGeneratorDetailsQuestions(options);

                return of(questions);
            })
        );
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.HeatGeneratorDetails);
    }

    public onPrefillInit(): void {
        this.heatloadPrefillService.setAsFinished(FormName.HeatGeneratorDetails);
    }

    public isExpanded$ = this.heatloadStore.sameValueSubmited$.pipe(map(form => form?.formName === FormName.EnergySource));
}
