import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, map, catchError, tap, delay, withLatestFrom, mergeMap } from 'rxjs/operators';
import { EMPTY, forkJoin, iif, Observable, of } from 'rxjs';
import {
    CalculationsControllerService,
    CreateProjectRequestPost,
    HeatLoadCalculationGetById,
    PartialHeatLoadsRequestPost,
    ProjectResponseGetById,
    ProjectsControllerService,
    ReportsControllerService,
    UpdateProjectRequestPartial
} from '@customer-apps/api-planning-projects';
import {
    ApiErrors,
    ApplicationId,
    ElectricityTariff,
    EnergySource,
    ExternalTool,
    ResponseSummaryActionType,
    RoutePaths,
    RouterLinks
} from '@customer-apps/shared/enums';
import { ErrorResponse } from '@customer-apps/shared/interfaces';
import {
    ApiErrorsMessageDictionary,
    getUpdatedProperties,
    ProjectUtils,
    ResponseSummaryActionDictionary
} from '@customer-apps/shared/utils';
import { LodashService, SnackbarService } from '@customer-apps/shared/services';
import { ExternalToolUrlService } from '../../services';
import { GlobalSpinnerService } from '../../core';
import { SettingsStore } from '../settings/settings.store';
import { AppSpecificDataActions } from '../app-specific-data/app-specific-data.actions';
import { ProjectAccessStore } from '../project-access/project-access.store';
import { ProjectActions } from './project.actions';
import { ProjectStore } from './project.store';

@Injectable()
export class ProjectEffects {
    public getProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                });
            }),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ action, project }) =>
                iif(
                    () => !!project,
                    of(project).pipe(tap(() => this.globalSpinnerService.reset())),
                    this.projectsControllerService.projectsControllerGetProject({
                        id: action.id,
                        detailsForApplicationId: ApplicationId.ViGuidePlanning as any
                    })
                ).pipe(
                    switchMap(response => [
                        AppSpecificDataActions.setAppSpecificData({
                            viguidePlanningSpecificData: (response as any).viguidePlanningSpecificData
                        }),
                        ProjectActions.getProjectSuccess({ project: response! })
                    ]),
                    catchError(error => {
                        this.projectAccessStore.unlockProject(action.id);
                        this.navigateTo404Page(error.error);
                        return this.handleApiErrors(error.error as ErrorResponse);
                    })
                )
            )
        )
    );

    public reloadProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.reloadProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                });
            }),
            switchMap(({ id, successActionOptions, successAction }) => {
                return this.projectsControllerService
                    .projectsControllerGetProject({
                        id,
                        detailsForApplicationId: ApplicationId.ViGuidePlanning as any
                    })
                    .pipe(
                        switchMap(response => [
                            AppSpecificDataActions.setAppSpecificData({
                                viguidePlanningSpecificData: (response as any).viguidePlanningSpecificData
                            }),
                            successAction({ ...successActionOptions, project: response })
                        ]),
                        catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                    );
            })
        )
    );

    public getProjectSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.getProjectSuccess),
                tap(action => {
                    if (action.redirectUrl) {
                        this.router.navigate([action.redirectUrl]);
                    }
                })
            ),
        { dispatch: false }
    );

    public updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.settingsStore.languageCode$, this.projectStore.project$, (action, languageCode, project) => ({
                action,
                languageCode,
                project
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.request,
                    building: {
                        ...payload.action.request.building,
                        heatLoadCalculations: payload.action.request.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                ...action,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, [
                    'electricityTariff',
                    'viguidePlanningSpecificData'
                ])
            })),
            switchMap(({ id, body, successAction, successActionOptions }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id,
                            successAction,
                            successActionOptions
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public setReportConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.setReportConfiguration),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            map(payload => ({
                ...payload,
                request: {
                    viguidePlanningSpecificData: {
                        ...payload.project?.viguidePlanningSpecificData,
                        data: { reportConfiguration: payload.action.reportConfiguration }
                    }
                }
            })),
            map(({ action, project, request }) => ({
                id: project?.id!,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, ['viguidePlanningSpecificData'])
            })),
            switchMap(({ id, body }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() =>
                        AppSpecificDataActions.setAppSpecificData({
                            viguidePlanningSpecificData: body.viguidePlanningSpecificData!
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public migrationVerificationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.migrationVerificationSuccess),
                tap(({ callback, project }) => {
                    if (callback) {
                        callback();
                        return;
                    }
                    this.router.navigate([`${RouterLinks.Project}${project.id}`]);
                })
            ),
        { dispatch: false }
    );

    public saveHeatPumpIntermediarySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.saveHeatPumpIntermediarySuccess),
                tap(({ project }) => {
                    this.externalToolUrlService.redirectToExternalToolByProjectId(ExternalTool.HeatPumpPlanner, {
                        projectId: project.id,
                        navigateTo: 'system'
                    });
                })
            ),
        { dispatch: false }
    );

    public saveHeatPumpIntermediary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.saveHeatPumpIntermediary),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.settingsStore.languageCode$, this.projectStore.project$, (action, languageCode, project) => ({
                action,
                languageCode,
                project
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.request,
                    building: {
                        ...payload.action.request.building,
                        heatLoadCalculations: payload.action.request.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                ...action,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, ['household'])
            })),
            switchMap(({ id, body }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() => ProjectActions.reloadProject({ id, successAction: ProjectActions.saveHeatPumpIntermediarySuccess })),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public saveHeatloadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.saveHeatloadSuccess),
                tap(({ redirectUrl }) => {
                    this.router.navigate([redirectUrl]);
                })
            ),
        { dispatch: false }
    );

    public selectHeatloadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.selectHeatloadSuccess),
                tap(() => {
                    const message = this.translate.instant('SNACKBAR.HEATLOAD_SELECTION_CHANGED');
                    this.snackbarService.showInfo(message);
                })
            ),
        { dispatch: false }
    );

    public saveHeatload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.saveHeatload),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.projectStore.project$, this.settingsStore.languageCode$, (action, project, languageCode) => ({
                action,
                project,
                languageCode
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.projectPatch,
                    building: {
                        ...payload.action.projectPatch.building,
                        heatLoadCalculations: payload.action.projectPatch.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                redirectUrl: action.redirectUrl,
                id: project?.id!,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial)
            })),
            switchMap(({ body, redirectUrl, id }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id,
                            successActionOptions: { redirectUrl },
                            successAction: ProjectActions.saveHeatloadSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public savePhotovoltaicIntermediarySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.savePhotovoltaicIntermediarySuccess),
                tap(({ projectId }) => {
                    this.externalToolUrlService.redirectToExternalToolByProjectId(ExternalTool.PhotovoltaicPlanner, {
                        projectId: projectId!,
                        navigateTo: 'system'
                    });
                })
            ),
        { dispatch: false }
    );

    public savePhotovoltaicIntermediary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.savePhotovoltaicIntermediary),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.settingsStore.languageCode$, this.projectStore.project$, (action, languageCode, project) => ({
                action,
                languageCode,
                project
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.request,
                    building: {
                        ...payload.action.request.building,
                        heatLoadCalculations: payload.action.request.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                id: action.projectId,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial)
            })),
            switchMap(({ id, body }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    map(() => ProjectActions.savePhotovoltaicIntermediarySuccess({ projectId: id })),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.createProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.SAVING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.SAVING_PROJECT'
                });
            }),
            switchMap(({ request }) =>
                this.projectsControllerService.projectsControllerCreateProject({ body: request as CreateProjectRequestPost }).pipe(
                    delay(0),
                    map(project =>
                        ProjectActions.reloadProject({
                            id: project.id!,
                            successActionOptions: { redirectUrl: `${RouterLinks.Project}${project.id}` },
                            successAction: ProjectActions.getProjectSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public beforeUpdateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.beforeUpdateProject),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ action, project }) =>
                iif(
                    () =>
                        !LodashService.isNil(project?.building.heatLoadCalculations?.[0]) &&
                        this.hasHeatLoadChanged(project, action.request) &&
                        !this.hasProjectTypeChanged(project, action.request),
                    of(
                        ProjectActions.recalculateHeatloads({
                            id: action.id,
                            request: action.request,
                            redirectUrl: action.redirectUrl
                        })
                    ),
                    iif(
                        () =>
                            !LodashService.isNil(project?.building.heatLoadCalculations?.[0]) &&
                            this.hasProjectTypeChanged(project, action.request),
                        of(
                            // Remove heat load calculations and update project
                            ProjectActions.updateProject({
                                id: action.id,
                                request: {
                                    ...action.request,
                                    building: {
                                        ...action.request.building,
                                        heatLoadCalculations: []
                                    }
                                },
                                successAction: ProjectActions.getProjectSuccess,
                                successActionOptions: { redirectUrl: action.redirectUrl }
                            })
                        ).pipe(
                            tap(() => {
                                const messageKey =
                                    project?.building.heatLoadCalculations?.length! > 1
                                        ? 'SNACKBAR.HEATLOADS_REMOVED'
                                        : 'SNACKBAR.HEATLOAD_REMOVED';
                                const message = this.translate.instant(messageKey);
                                this.snackbarService.showInfo(message, { duration: 5000 });
                            })
                        ),
                        iif(
                            () =>
                                !LodashService.isNil(project?.building.heatLoadCalculations?.[0]) &&
                                this.hasHeatLoadChanged(project, action.request),
                            of(
                                ProjectActions.recalculateHeatloads({
                                    id: action.id,
                                    request: action.request,
                                    redirectUrl: action.redirectUrl
                                })
                            ),
                            of(
                                ProjectActions.updateProject({
                                    id: action.id,
                                    request: action.request,
                                    successAction: ProjectActions.getProjectSuccess,
                                    successActionOptions: { redirectUrl: action.redirectUrl }
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    public recalculateHeatloads$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.recalculateHeatloads),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.RECALCULATING_HEATLOAD',
                    loadingSubtext: 'LOADER.SUBTEXT.RECALCULATING_HEATLOAD'
                });
            }),
            map(({ action, project }) => {
                const heatloads: PartialHeatLoadsRequestPost[] = (project?.building.heatLoadCalculations || [])?.map(heatload => {
                    const partialHeatload: PartialHeatLoadsRequestPost = {
                        numberOfPersons: action.request.building?.numberOfPersons || project?.building.numberOfPersons,
                        baseHeatLoad: heatload?.baseHeatLoad
                    } as PartialHeatLoadsRequestPost;
                    if (
                        this.hasElectricityTariffChanged(project, action.request) ||
                        this.hasDailyLockPeriodChanged(project, action.request)
                    ) {
                        const requestElectricityHeatPump = action.request?.energyPrices?.find(
                            price => price.energySource === EnergySource.HeatPumpElectricity
                        );
                        partialHeatload.dailyLockPeriod = requestElectricityHeatPump?.dailyLockPeriod;
                    } else if (project?.electricityTariff === ElectricityTariff.LockPeriodTariff) {
                        const projectElectricityHeatPump = project?.energyPrices?.find(
                            price => price.energySource === EnergySource.HeatPumpElectricity
                        );
                        partialHeatload.dailyLockPeriod = projectElectricityHeatPump?.dailyLockPeriod;
                    }
                    return partialHeatload;
                });
                return {
                    action,
                    project,
                    heatloads
                };
            }),
            switchMap(({ action, heatloads }) =>
                forkJoin(
                    heatloads.map(heatload => this.calculationsControllerService.calculationsControllerPartialHeatLoads({ body: heatload }))
                ).pipe(
                    map(newHeatloads =>
                        ProjectActions.recalculateHeatloadsSuccess({
                            heatloads: newHeatloads,
                            id: action.id,
                            request: action.request,
                            redirectUrl: action.redirectUrl
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public recalculateHeatloadsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.recalculateHeatloadsSuccess),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            map(({ action, project }) => {
                const heatloads = project?.building.heatLoadCalculations as HeatLoadCalculationGetById[];

                const request: UpdateProjectRequestPartial = {
                    ...action.request,
                    building: {
                        ...action.request.building,
                        heatLoadCalculations: heatloads.map((heatload, i) => ({
                            ...LodashService.omit(heatload, ['isSelected']),
                            warmWaterHeatLoad: action.heatloads[i].warmWaterHeatLoad,
                            lockTimeHeatLoad: action.heatloads[i].lockTimeHeatLoad
                        }))
                    }
                };
                return {
                    id: action.id,
                    redirectUrl: action.redirectUrl,
                    request
                };
            }),
            tap(({ request }) => {
                const recalculationActionTypes =
                    request.building?.heatLoadCalculations?.length! === 1
                        ? ResponseSummaryActionType.CalculationMethodRecalculated
                        : ResponseSummaryActionType.SelectedCalculationMethodRecalculated;
                const messageKey = ResponseSummaryActionDictionary[recalculationActionTypes];
                const message = this.translate.instant(messageKey!);
                this.snackbarService.showInfo(message, { duration: 5000 });
            }),
            delay(0),
            switchMap(({ id, request, redirectUrl }) =>
                of(
                    ProjectActions.updateProject({
                        id,
                        request,
                        successAction: ProjectActions.getProjectSuccess,
                        successActionOptions: { redirectUrl }
                    })
                )
            )
        )
    );

    public deleteHeatload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.deleteHeatload),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.DELETING_HEATLAOD',
                    loadingSubtext: 'LOADER.SUBTEXT.DELETING_HEATLAOD'
                });
            }),
            withLatestFrom(this.projectStore.project$, this.settingsStore.languageCode$, (action, project, languageCode) => ({
                action,
                project,
                languageCode
            })),
            map(payload => ({
                ...payload,
                request: {
                    building: {
                        heatLoadCalculations: payload.project?.building?.heatLoadCalculations
                            ?.map(heatLoad => LodashService.omit(heatLoad, ['isSelected']))
                            ?.filter((heatLoad: any) => heatLoad.method !== payload.action.method)
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(payload => ({
                ...payload,
                id: payload.project?.id!,
                body: getUpdatedProperties(payload.project!, payload.request as UpdateProjectRequestPartial)
            })),
            switchMap(({ action, body, project, id }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    tap(() => {
                        const messageKey = 'SNACKBAR.HEATLOAD_DELETED';
                        const message = this.translate.instant(messageKey);
                        if (project?.building.heatLoadCalculations?.length! > 1) {
                            const calculation = project?.building.heatLoadCalculations?.find(heatload => heatload.method === action.method);
                            if (!calculation?.isSelected) {
                                this.snackbarService.showInfo(message);
                            }
                        } else if (!ProjectUtils.hasPlanningsWithProductsAmongAllVariants(project!)) {
                            this.snackbarService.showInfo(message);
                        }
                    }),
                    map(() =>
                        ProjectActions.reloadProject({
                            id,
                            successAction: ProjectActions.deleteHeatloadSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public selectHeatload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.selectHeatload),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.SELECTING_HEATLAOD',
                    loadingSubtext: 'LOADER.SUBTEXT.SELECTING_HEATLAOD'
                });
            }),
            switchMap(action =>
                this.calculationsControllerService.calculationsControllerSelect({ body: action.request }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id: action.request.projectId,
                            successAction: ProjectActions.selectHeatloadSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public closeProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.closeProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.CLOSING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.CLOSING_PROJECT'
                });
            }),
            switchMap(({ projectId }) =>
                this.projectsControllerService.projectsControllerFinalizeProject({ id: projectId }).pipe(
                    map(response =>
                        ProjectActions.closeProjectSuccess({
                            response
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public completeProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.completeProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.COMPLETING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.COMPLETING_PROJECT'
                });
            }),
            switchMap(({ projectId }) =>
                this.projectsControllerService.projectsControllerFinalizeProject({ id: projectId }).pipe(
                    map(response =>
                        ProjectActions.completeProjectSuccess({
                            response
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public uploadProjectReportSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.uploadProjectReportSection),
            mergeMap(payload =>
                this.reportsControllerService.reportsControllerUploadSection(payload).pipe(
                    map(() => ProjectActions.uploadProjectReportSectionSuccess()),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    private hasHeatLoadChanged(currentProject: ProjectResponseGetById | undefined, requestProject: UpdateProjectRequestPartial): boolean {
        return (
            this.hasNumberOfPersonsChanged(currentProject, requestProject) ||
            this.hasElectricityTariffChanged(currentProject, requestProject) ||
            this.hasDailyLockPeriodChanged(currentProject, requestProject)
        );
    }

    private hasNumberOfPersonsChanged(
        currentProject: ProjectResponseGetById | undefined,
        requestProject: UpdateProjectRequestPartial
    ): boolean {
        return (
            !!requestProject.building?.numberOfPersons &&
            currentProject?.building.numberOfPersons !== requestProject.building?.numberOfPersons
        );
    }

    private hasElectricityTariffChanged(
        currentProject: ProjectResponseGetById | undefined,
        requestProject: UpdateProjectRequestPartial
    ): boolean {
        if (requestProject?.electricityTariff && currentProject?.electricityTariff !== requestProject?.electricityTariff) {
            return (
                currentProject?.electricityTariff === ElectricityTariff.LockPeriodTariff ||
                requestProject?.electricityTariff === ElectricityTariff.LockPeriodTariff
            );
        }
        return false;
    }

    private hasDailyLockPeriodChanged(
        currentProject: ProjectResponseGetById | undefined,
        requestProject: UpdateProjectRequestPartial
    ): boolean {
        if (requestProject?.electricityTariff === ElectricityTariff.LockPeriodTariff) {
            const currentElectricityHeatPump = currentProject?.energyPrices?.find(
                price => price.energySource === EnergySource.HeatPumpElectricity
            );
            const requestElectricityHeatPump = requestProject?.energyPrices?.find(
                price => price.energySource === EnergySource.HeatPumpElectricity
            );
            return currentElectricityHeatPump?.dailyLockPeriod !== requestElectricityHeatPump?.dailyLockPeriod;
        }
        return false;
    }

    private hasProjectTypeChanged(
        currentProject: ProjectResponseGetById | undefined,
        requestProject: UpdateProjectRequestPartial
    ): boolean {
        return !!requestProject.projectType && currentProject?.projectType !== requestProject.projectType;
    }

    private handleApiErrors(error: ErrorResponse): Observable<any> {
        this.globalSpinnerService.reset();
        const messageKey = ApiErrorsMessageDictionary[error.message as ApiErrors];
        if ((error.status === 409 || error.status === 422) && messageKey) {
            const message = this.translate.instant(messageKey);
            const action = this.translate.instant('COMMON.BUTTONS.GOT_IT');
            this.snackbarService.showInfo(message, {
                duration: 0,
                action
            });

            if (error.message === ApiErrors.AddressUsedInOpenedProject || error.message === ApiErrors.AddressUsedInClosedProject) {
                return of(ProjectActions.addressCollision());
            }
        }
        return EMPTY;
    }

    private navigateTo404Page(error: ErrorResponse): void {
        if (error.status === 404) {
            this.router.navigate([RoutePaths.Error404]);
        }
    }

    constructor(
        private actions$: Actions,
        private router: Router,
        private globalSpinnerService: GlobalSpinnerService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private settingsStore: SettingsStore,
        private projectsControllerService: ProjectsControllerService,
        private reportsControllerService: ReportsControllerService,
        private calculationsControllerService: CalculationsControllerService,
        private projectStore: ProjectStore,
        private projectAccessStore: ProjectAccessStore,
        private externalToolUrlService: ExternalToolUrlService
    ) {}
}
